import React from 'react';
import { useAxiosSWR } from "../../../axiosInstance";
import { ShimmerEffect } from "./ShimmerEffect";

const AdSquare = ({ campaign }) => {
    
    const className = campaign?.includes("square") ? "min-h-[250px] " : ""; //"md:h-[90px] h-[50px]"

    const { data: data, isLoading, error: isError } = useAxiosSWR(campaign ? `/v1/ads/${campaign}` : null);

    if (isLoading) {
        return <div className={className}></div>;
    }
    if(isError){
        return '';
    }
    return (
        <div className={`flex justify-center items-center ${className}`}>
            <a href={data?.target || "/"} target='_blank' rel="nofollow">
                <ShimmerEffect src={data?.image} alt="ads" className={`${className} w-auto`} />
            </a>
        </div>
    );
}
export default React.memo(AdSquare);