import { Link, useLocation } from "react-router-dom";
import { useAxiosSWR } from "root/axiosInstance";
import { formatDate } from "src/helpers/dates";
import { getSanitizedArray } from "src/helpers/general";
import SEOMeta from "../Home/common/SEOMeta";
import AppContext, { URL_TYPES } from "src/context";
import React, { useContext, useEffect } from "react";
import { doScrollToTop } from "../../../helpers/general";

export const PointsTable = () => {
    const { data, isLoading, error } = useAxiosSWR("/v1/series/recent");
    const seriesData = getSanitizedArray(data);
    const appContext = useContext(AppContext);
    const { pathname: pathName } = useLocation();
    useEffect(() => {
        const urlInfoObj = {
            type: URL_TYPES.POINT_TABLE,
            path: pathName,
            reqState: null,
        };
        appContext.updateUrlInfo(urlInfoObj);
    }, [pathName]);

    if (!isLoading && !error && !data) {
        return null;
    }

    return (
        <>
            <SEOMeta slug="points-table" />
            <div className="mt-4 bg-white rounded-2xl border border-[#E3E3E4] md:block hidden">
                <div className="md:text-2xl text-lg font-semibold md:py-6">
                    <div className='md:min-h-[18px] md:min-w-[180px] md:p-0 px-2 text-[24px] leading-[28px] text-[#000000] font-bold'>
                        <h1 className='text-lg md:text-2xl text-center'>Points Table</h1>
                    </div>
                </div>
            </div>

            <div className="md:mx-0 mx-1 mt-4 bg-white rounded-2xl border border-[#E3E3E4] p-5">
                <div className="md:text-2xl text-lg font-semibold md:py-6 md:hidden">
                    <div className='md:min-h-[18px] md:min-w-[180px] md:p-0 px-2 md:py-2 text-[24px] leading-[28px] text-[#000000] font-bold'>
                        <h1 className='text-lg md:text-2xl text-center'>Points Table</h1>
                        <div className="pt-4 custom-border-lr border-b"></div>
                    </div>
                </div>
                {isLoading && <h4>Loading..</h4>}
                {error && <div className="p-4"></div>}
                {seriesData?.map((series, index) => {
                    return (
                        <Link to={`/points-table/${series.slug}`} key={series.cid} onClick={doScrollToTop}>
                            <div className={`flex items-center justify-between py-3 ${index === seriesData.length - 1 ? "" : "border-b border-gray-300" }`}>
                                <div className={`flex`} style={{ columnGap: '1rem' }}>
                                    <div>
                                        <div className="bg-[#3F3F3F] h-[42px] w-[42px] rounded-lg" >
                                        </div>
                                    </div>
                                    <div>
                                        <p className="font-semibold lg:text-lg sm:text-sm">{series.title}</p>
                                        <p className="text-[#91949B] text-xs">
                                            {formatDate(series.date_start, false)} {" - "} {formatDate(series.date_end, false)}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.37656 6L2.37656 12L0.976562 10.6L5.55156 6L0.976562 1.4L2.37656 -6.11959e-08L8.37656 6Z" fill="#1C1B1F" />
                                    </svg>
                                </div>
                            </div>
                        </Link>
                    )
                })}
            </div>

        </>
    )
}