import React from 'react';
import { formatTime } from "../../../helpers/dates";
import { Link } from "react-router-dom";
import { MatchFormatShortNames } from "../../pages/Home/constants";
import liveIcon from '../../../Assets/Ellipse 6.png';
import { doScrollToTop } from "../../../helpers/general";

export const MatchBox = ({ match, customClass: classname, type }) => {
  return (
    <div
      data-matchid={match?.match_id}
      data-testid={`LiveMatchBox__Container__${match?.match_id}`}
      className="md:hover:bg-gray-100 rounded-xl transition-colors border md:border-none border-[#E3E3E4]"
    >
      <div className={`p-4 ${type === 'series' ? 'items-center flex' : ''}`}>
        <div>
          {type === 'series' ? (
            <div className="md:flex justify-between">
              <Link
                to={`/series/${match?.slug}/matches/${match.slug}/${match.status === "Scheduled" ? "matchinfo" : match.status === "Live" ? "scorecard" : "commentary"}`}
                onClick={doScrollToTop}
              >
                <div>
                  <div className="flex items-center md:w-[400px] md:max-w-[400px] text-left">
                    <img
                      src={match?.team_a?.logo}
                      alt={match?.team_a?.name}
                      className={`${classname} w-5 h-5 mr-1`}
                    />
                    <p className={`${classname} text-sm mx-1`}>
                      {match?.team_a?.short_name}
                    </p>
                    <p className="mx-1 text-xs text-[#787878]">vs</p>
                    <img
                      src={match?.team_b?.logo}
                      alt={match?.team_b?.name}
                      className={`${classname} w-5 h-5 mx-1`}
                    />
                    <p className={`${classname} text-sm mx-1`}>
                      {match?.team_b?.short_name}
                    </p>
                    <p className={`${classname} text-[10px] bg-[#f0ddb6] text-[#ce9942] font-medium flex items-center rounded-full ml-1 px-2 whitespace-nowrap"}`}>
                      {MatchFormatShortNames[match?.match_format] || match?.match_format}
                    </p>
                  </div>
                  <p className="pt-1 md:w-[400px] md:max-w-[400px] text-[#787878] text-xs font-medium">
                    {match?.venue}
                  </p>
                  {match?.status_str === 'Live' || match?.status_str === 'Completed' ? (
                    <p className={`${match?.status_str === 'Completed' ? 'text-[#019F0B]' : 'text-[#3A32D1]'} text-xs pt-1 font-semibold md:w-[400px] md:max-w-[400px]`}
                    dangerouslySetInnerHTML={{ __html: match?.status_note }}
                    >
                    </p>
                  ) : null}
                </div>
              </Link>
              <div className="text-left md:mt-0 mt-2 md:ml-3">
                <p className={`${classname} min-h-5 min-w-full text-sm font-bold flex items-center`}>
                  {formatTime(match?.date_start)?.localTime}
                  <span className="flex items-center ml-2">
                    {match?.status_str === 'Live' && (
                      <>
                        <img src={liveIcon} alt="live" />
                        <span className={`${classname} text-[10px] text-green-600`}>
                          {match?.status_str}
                        </span>
                      </>
                    )}
                  </span>
                </p>
                <p className="text-[10px] text-[#787878]">
                  <span className={`${classname} min-h-[16px] min-w-[100px]`}>
                    {formatTime(match?.date_start)?.utcTime + " GMT"} / {" "}
                  </span>
                  <span className={`${classname} min-h-[16px] min-w-[100px]`}>
                    {formatTime(match?.date_start)?.localTime + " LOCAL"}
                  </span>
                </p>
              </div>
            </div>
          ) : (
            <Link
              to={`/series/${match?.tournament_slug}/matches/${match?.slug}/${match.status === "Scheduled" ? "matchinfo" : match.status === "Live" ? "scorecard" : "commentary"}`}
              onClick={doScrollToTop}
            >
              <div className='md:grid grid-cols-9'>
                <div className='flex md:block justify-between items-center col-span-3'>
                  <Link to={`/series/${match?.tournament_slug}/schedule`} >
                    <p className={`${classname} text-[#000000] opacity-80 min-h-[20px] text-sm font-medium md:w-[250px]`}>
                      {match?.tournament_name}
                    </p>
                  </Link>
                  <div className='md:hidden block'>
                    <p className={`${classname} text-[10px] bg-[#f0ddb6] text-[#ce9942] font-medium flex items-center rounded-full ml-1 px-2 whitespace-nowrap"}`}>
                      {MatchFormatShortNames[match?.format] || match?.format}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between md:items-start items-center col-span-6 md:grid grid-cols-3'>
                  <div className='md:mt-0 mt-2 md:w-[325px] xl:w-[375px] col-span-2'>
                    <div className="md:flex items-center md:w-[320px] md:max-w-[320px] text-left">
                      <div className='flex'>
                        <div className='flex min-w-20 md:min-w-0'>
                          <img
                            src={match?.teama?.logo}
                            alt={match?.teama?.name}
                            className={`${classname} w-5 h-5 md:mr-1`}
                          />
                          <p className={`${classname} text-sm mx-1`}>
                            {match?.teama?.short_name}
                          </p>
                        </div>
                        <div className={`${classname} text-sm mx-1 md:mx-0 md:hidden`}>
                          {match?.teama?.scores}
                          <span className='text-[9px] pl-0.5 text-[#808080]'>
                            {match?.teama?.overs && `(${match?.teama?.overs})`}
                          </span>
                        </div>
                      </div>
                      <p className="mx-1 text-xs text-[#787878] hidden md:block">vs</p>
                      <div className='flex mt-2 md:mt-0'>
                        <div className='flex min-w-20 md:min-w-0'>
                          <img
                            src={match?.teamb?.logo}
                            alt={match?.teamb?.name}
                            className={`${classname} w-5 h-5 md:mx-1`}
                          />
                          <p className={`${classname} text-sm mx-1`}>
                            {match?.teamb?.short_name}
                          </p>
                        </div>
                        <div className={`${classname} text-sm mx-1 md:mx-0 md:hidden`}>
                          {match?.teamb?.scores}
                          <span className='text-[9px] pl-0.5 text-[#808080]'>
                            {match?.teamb?.overs && `(${match?.teamb?.overs})`}
                          </span>
                        </div>
                      </div>
                      <div className='hidden md:block'>
                        <p className={`${classname} text-[10px] bg-[#f0ddb6] text-[#ce9942] font-medium flex items-center rounded-full ml-1 px-2 whitespace-nowrap"}`}>
                          {MatchFormatShortNames[match?.format] || match?.format}
                        </p>
                      </div>
                    </div>
                    <p className={`${classname} text-[#787878] text-xs font-medium pt-1 min-h-[20px] md:min-w-[320px] hidden md:block`}>
                      {match?.subtitle}, {match?.venue}
                    </p>
                    <p className={`${match?.status === 'Completed' ? 'text-[#019F0B]' : 'text-[#3A32D1]'} text-xs pt-1 font-semibold hidden md:block`}
                    dangerouslySetInnerHTML={{ __html: match?.status_note }}
                    >
                    </p>
                  </div>
                  <div className={`md:w-max md:mx-auto md:mr-4 md:mt-0 col-span-1 mt-2 ${match?.status === 'Live' ? "md:block hidden" : ""}`}>
                    <div className={`${classname} min-h-5 min-w-full text-sm font-bold flex md:justify-start justify-end md:items-center`}>
                      {formatTime(match?.date_start_utc)?.localTime}
                      <div>
                        <span className="flex items-center md:ml-2">
                          {match?.status === 'Live' && (
                            <>
                              <img src={liveIcon} alt="live" />
                              <span className={`${classname} text-[10px] text-green-600`}>
                                {match?.status}
                              </span>
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="text-[10px] text-[#787878]">
                      <div className={`${classname} min-h-[16px] text-end md:text-start min-w-[100px]`}>
                        {formatTime(match?.date_start_utc)?.utcTime + " GMT"}
                      </div>
                    </div>
                  </div>

                </div>
                <p className={`${classname} text-[#787878] text-xs font-medium pt-1 min-h-[20px] md:min-w-[320px] md:hidden`}>
                  {match?.subtitle}, {match?.venue}
                </p>
                <div className='flex justify-between items-center md:hidden'>
                  <p className={`${match?.status === 'Completed' ? 'text-[#019F0B]' : 'text-[#3A32D1]'} text-xs pt-1 font-semibold `}
                    dangerouslySetInnerHTML={{ __html: match?.status_note }}
                  >
                  </p>
                  <div className='md:hidden'>
                    <span className="">
                      {match?.status === 'Live' && (
                        <div className='flex items-center gap-0.5'>
                          <img src={liveIcon} alt="live" />
                          <div className={`${classname} text-[10px] leading-[10px] text-green-600`}>
                            {match?.status}
                          </div>
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};