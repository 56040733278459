import React from "react";
import { Link, useLocation } from "react-router-dom";
import { doScrollToTop } from "../../helpers/general";
import homeIcon from "../../Assets/mobilenavbar/home.svg";
import newsIcon from "../../Assets/mobilenavbar/news.svg";
import scheduleIcon from "../../Assets/mobilenavbar/schedule.svg";
import seriesIcon from "../../Assets/mobilenavbar/series.svg";
import fantacyIcon from "../../Assets/mobilenavbar/fantasy.svg";
import homeIconUnSelect from "../../Assets/mobilenavbar/home-unselect.svg";
import newsIconUnSelect from "../../Assets/mobilenavbar/news-unselect.svg";
import scheduleIconUnSelect from "../../Assets/mobilenavbar/schedule-unselect.svg";
import seriesIconUnSelect from "../../Assets/mobilenavbar/series-unselect.svg";
import fantacyIconUnSelect from "../../Assets/mobilenavbar/fantasy-unselect.svg";
const links = [
  {
    label: "Home",
    target: "/",
    icon: homeIcon,
    route: "",
    iconUnSelect: homeIconUnSelect,
  },
  {
    label: "Schedule",
    target: "/schedule/live",
    icon: scheduleIcon,
    route: "schedule",
    iconUnSelect: scheduleIconUnSelect,
  },
  {
    label: "Series",
    target: "/series",
    icon: seriesIcon,
    route: "series",
    iconUnSelect: seriesIconUnSelect,
  },
  {
    label: "News",
    target: "/cricket-news",
    icon: newsIcon,
    route: "news",
    iconUnSelect: newsIconUnSelect,
  },
  {
    label: "Fantasy",
    target: "/fantasy",
    icon: fantacyIcon,
    route: "fantasy",
    iconUnSelect: fantacyIconUnSelect,
  },
];
const MobileNavbar = () => {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];

  return (
    <div className="bg-[#FFFFFF] h-[84px] rounded-t-xl shadow-sm">
      <div className="grid grid-cols-5 h-16 place-content-center">
        {links.map(({ label, target, icon, iconUnSelect, route }, i) => (
          <div key={i} className="h-full w-full ">
            <Link
              to={target}
              onClick={doScrollToTop}
              className="block h-16 py-3"
            >
              <img
                src={pathname === route ? icon : iconUnSelect}
                alt={label}
                className=" mx-auto"
              />
              <p className="text-xs w-max mx-auto">{label}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileNavbar;
