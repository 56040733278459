import React from 'react';

export default function Banner({ largeBanner, smallBanner, title, content, isSearchbar }) {
    return (
        <div
            className={`relative bg-cover bg-center bg-no-repeat ${isSearchbar ? 'h-[368px] lg:h-[326px]' : 'h-[230px] lg:h-[216px]'} md:px-[80px] lg:px-[140px] xl:px-[180px]`}>
            <div className="flex items-end md:items-center h-full text-white">
                <div className="w-full md:w-[65%] text-center md:text-left -mt-14 md:mt-0">
                    <h2 className='text-[24px] md:text-[40px] mb-0 md:mb-3 font-semibold'>{title}</h2>
                    <p className='text-[14px] md:text-[16px] opacity-50 font-light mb-5 md:mb-0 px-2 md:px-0'>{content}</p>
                    {isSearchbar &&
                        <div className='h-[58px]'>

                        </div>
                    }
                </div>
            </div>
            <img src={largeBanner} alt="Large Banner" className='absolute h-full w-full -z-10 inset-0 object-cover object-right-top hidden md:block' />
            <img src={smallBanner} alt="small Banner" className='absolute h-full w-full -z-10 inset-0 object-cover object-center md:hidden' />
        </div>
    );
}
