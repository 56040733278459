import React, { useState } from "react";
import { useAxiosSWR } from "root/axiosInstance";
import YouTubeModal from "../../../modals/YouTubeModal";
import { ShimmerEffect } from "../../../common/ShimmerEffect";
import { getSanitizedArray } from "../../../../helpers/general";
import VideoIcon from "../../../common/VideoIcon";
import { formatDate } from '../../../../helpers/dates';
const shimmerAry = []
for (let i = 0; i < 4; i++) {
  shimmerAry.push({ isShimmer: true, video_url: "" })
}
const ExclusivelyVideos = () => {
  const {
    data,
    error: isError,
    isLoading,
  } = useAxiosSWR("v1/misc/exclusiveVideos");
  const [open, setOpen] = useState(false);
  const [youTubeUrl, setYouTubeUrl] = useState("");
  const ExclusivelyVideosData = getSanitizedArray(isLoading ? shimmerAry : data);

  const handleOpenModal = (video_url) => {
    setOpen(true);
    setYouTubeUrl(video_url);
  };
  const onClose = () => {
    setOpen(false);
  };


  if (isError) {
    return <h2></h2>;
  }

  return (
    <div className="bg-white p-4 rounded-xl border">
      <h2 className="md:text-lg text-sm font-bold text-center">▶️ EXCLUSIVE VIDEOS</h2>
      {ExclusivelyVideosData.map((obj, i) => (
        <div className={`relative ${i < ExclusivelyVideosData.length - 1 ? "border-b" : ""}`} key={i} >
          <div
            onClick={() => handleOpenModal(obj.video_url)}
            className="cursor-pointer"
          >
            <div className="my-4 relative">
              <div className="relative rounded-xl overflow-hidden">
                {obj?.isShimmer ? <div className="bg-gray-300 loading h-[149px] w-full"></div> :
                  <ShimmerEffect
                    src={obj.thumb_url}
                    className="h-[166px] md:h-[149px] w-full object-cover"
                    alt="video_thumb_url"
                  />}
                <div className="absolute inset-0 flex items-center justify-center ">
                  <VideoIcon />
                </div>
              </div>
              {/* class=" min-h-[15px] w-full live-match text-[#797979] text-[10px] font-semibold overflow-hidden" */}
              {obj?.isShimmer ? <div className="bg-gray-300 mt-2 loading h-12 w-full"></div> : <p className="mt-2 md:font-medium font-semibold md:text-base text-sm overflow-hidden live-match">{obj.title}</p>}
              <div className="text-[#999999] text-[10px] mt-2.5">
                {
                  formatDate(obj.created_at)
                }
              </div>
            </div>
          </div>
        </div>
      ))}
      {!isLoading && <YouTubeModal open={open} onClose={onClose} videoUrl={youTubeUrl} />}
    </div>
  );
};

export default ExclusivelyVideos;
