import React from 'react'
import aboutusBanar from "../../Assets/aboutus.jpg"
import aboutusBanarMobile from "../../Assets/aboutusMoile.jpg"
import img from "../../Assets/placeholder.png"
import ContactUs from '../common/ContactUs'
import Banner from './common/Banner'
import CustomPage from './common/CustomPage'
import { useAxiosSWR } from '../../../axiosInstance'

const AboutUs = () => {
    const { data, isLoading } = useAxiosSWR('/v1/misc/ourTeam');

    return (
        <div>
            <Banner
                title='About Us'
                content='Know More about us'
                largeBanner={aboutusBanar}
                smallBanner={aboutusBanarMobile}
            />
            <CustomPage customSlug='about-us' />
            {(isLoading === false && Array.isArray(data)) && <div className=''>
                <div className='bg-white border rounded-3xl mx-1 md:mx-[180px] min-h-[500px] md:mb-[80px] mb-[40px] md:rounded-[20px] md:py-10 py-6 md:px-[30px] px-[20px]'>
                    <div className={`md:min-h-[18px] md:min-w-[180px] flex items-center justify-center min-w-[50px]`}>
                        <span className="custom-border-left mr-3 md:mr-6 "></span>
                        <div className='text-[18px] md:text-[30px] leading-5 md:leading-10 py-3 md:py-1.5'><span className='font-bold'>Our</span> Team of Experts</div>
                        <span className="custom-border-right ml-3 md:ml-6 "></span>
                    </div>
                    <div className='md:grid grid-cols-3 md:gap-10 md:mt-4'>
                        {data.map(obj => <OurExpert obj={obj} key={obj.id} />)}
                    </div>
                </div>
                <ContactUs />
            </div>}
        </div>
    )
}

export default AboutUs

function OurExpert({ obj }) {
    return (
        <div className='md:p-5 p-3 border border-[#E3E3E4] rounded-2xl mt-6'>
            <img src={obj.image} alt="team-img"  className='w-full h-[236px] object-cover rounded-xl'/>
            <div className='md:pt-10 pt-4 text-center'>
                <div className='font-bold text-[#202020] text-[20px] leading-[20px]'>
                    {obj.name}
                </div>
                <p className='text-[12px] text-[#6C6C6C] pt-5'>
                    {obj.bio}
                </p>
                <div className='pt-5 text-[#3A32D1] text-[12px]'>
                    {obj.designation}
                </div>
            </div>
        </div>
    )
}