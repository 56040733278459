import React, { useEffect, useState } from 'react'
import authors from "../../../Assets/authorinstance.png"
import view from "../../../Assets/authorsInstanceViews.svg"
import post from '../../../Assets/postsicon.svg'
import views from '../../../Assets/viewsicon.svg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { formatDate } from '../../../helpers/dates'
import { useAxiosSWR } from '../../../../axiosInstance'
import usePost from '../../../hooks/usePost'

const data1 = [
  {
    "id": 790,
    "title": "Watch: 'Kya Hua, Bolo? You Cut That Out!' Morne Morkel's Hilarious Hindi Struggle with Suryakumar Yadav",
    "slug": "watch-kya-hua-bolo-you-cut-that-out-morne-morkels-hilarious-hindi-struggle-with-suryakumar-yadav",
    "excerpt": "Morne Morkel struggles hilariously with Hindi during a video with Suryakumar Yadav, leaving everyone in laughter as he asks to cut the awkward moment.",
    "isFeatured": false,
    "image": "https://static.cricketgully.com/news_featured/suryakumar-yadav-and-morne-morkel-25c44b7e14.webp",
    "shareText": "Watch: 'Kya Hua, Bolo? You Cut That Out!' Morne Morkel's Hilarious Hindi Struggle with Suryakumar Yadav\nhttps://cricketgully.com/cricket-news/watch-kya-hua-bolo-you-cut-that-out-morne-morkels-hilarious-hindi-struggle-with-suryakumar-yadav-790?utm_campaign=social_share&utm_medium=social_share",
    "date": "2024-10-09T13:13:18.424Z",
    "type": "news",
    "href": "https://cricketgully.com/cricket-news/watch-kya-hua-bolo-you-cut-that-out-morne-morkels-hilarious-hindi-struggle-with-suryakumar-yadav-790",
    "views": "5.6m"
  },
  {
    "id": 789,
    "title": "Ishan Kishan to Lead Jharkhand in Ranji Trophy After Controversial Withdrawal Last Season",
    "slug": "ishan-kishan-to-lead-jharkhand-in-ranji-trophy-after-controversial-withdrawal-last-season",
    "excerpt": "Ishan Kishan is back as Jharkhand's captain for the Ranji Trophy, leading a young team after his controversial withdrawal last season.",
    "isFeatured": false,
    "image": "https://static.cricketgully.com/news_featured/ishan-kishan-6a2a7a3dee.webp",
    "shareText": "Ishan Kishan to Lead Jharkhand in Ranji Trophy After Controversial Withdrawal Last Season\nhttps://cricketgully.com/cricket-news/ishan-kishan-to-lead-jharkhand-in-ranji-trophy-after-controversial-withdrawal-last-season-789?utm_campaign=social_share&utm_medium=social_share",
    "date": "2024-10-09T12:35:26.494Z",
    "type": "news",
    "href": "https://cricketgully.com/cricket-news/ishan-kishan-to-lead-jharkhand-in-ranji-trophy-after-controversial-withdrawal-last-season-789",
    "views": "5.6m"
  },
  {
    "id": 787,
    "title": "Watch: Captain Marnus Labuschagne's Hilarious Field Setting Stuns Umpire and Fans",
    "slug": "watch-captain-marnus-labuschagnes-hilarious-field-setting-stuns-umpire-and-fans",
    "excerpt": "Marnus Labuschagne's inventive bowling and unique field placements entertained fans as Queensland faced Western Australia in the Sheffield Shield.",
    "isFeatured": false,
    "image": "https://static.cricketgully.com/news_featured/marnus-labuschagne-45ad8a0c3d.webp",
    "shareText": "Watch: Captain Marnus Labuschagne's Hilarious Field Setting Stuns Umpire and Fans\nhttps://cricketgully.com/cricket-news/watch-captain-marnus-labuschagnes-hilarious-field-setting-stuns-umpire-and-fans-787?utm_campaign=social_share&utm_medium=social_share",
    "date": "2024-10-09T09:32:02.578Z",
    "type": "news",
    "href": "https://cricketgully.com/cricket-news/watch-captain-marnus-labuschagnes-hilarious-field-setting-stuns-umpire-and-fans-787",
    "views": "5.6m"
  },
  {
    "id": 785,
    "title": "Joe Root Surpasses Alastair Cook to Become England's Leading Run Scorer in Tests",
    "slug": "joe-root-surpasses-alastair-cook-to-become-englands-leading-run-scorer-in-tests",
    "excerpt": "Joe Root breaks Alastair Cook's record to become England's highest Test run-scorer, with 12,473 runs and aims for his 35th century.",
    "isFeatured": false,
    "image": "https://static.cricketgully.com/news_featured/joe-root-f3fc84cabc.webp",
    "shareText": "Joe Root Surpasses Alastair Cook to Become England's Leading Run Scorer in Tests\nhttps://cricketgully.com/cricket-news/joe-root-surpasses-alastair-cook-to-become-englands-leading-run-scorer-in-tests-785?utm_campaign=social_share&utm_medium=social_share",
    "date": "2024-10-09T07:43:17.412Z",
    "type": "news",
    "href": "https://cricketgully.com/cricket-news/joe-root-surpasses-alastair-cook-to-become-englands-leading-run-scorer-in-tests-785",
    "views": "5.6m"
  },
  {
    "id": 784,
    "title": "Watch: Rohit Sharma Stops His Car in Mumbai to Wish Fan on Her Birthday",
    "slug": "watch-rohit-sharma-stops-his-car-in-mumbai-to-wish-fan-on-her-birthday",
    "excerpt": "Rohit Sharma surprised a fan by stopping his car in Mumbai to wish her a happy birthday, a moment that quickly went viral on social media.",
    "isFeatured": false,
    "image": "https://static.cricketgully.com/news_featured/rohit-sharma-78cd387e62.webp",
    "shareText": "Watch: Rohit Sharma Stops His Car in Mumbai to Wish Fan on Her Birthday\nhttps://cricketgully.com/cricket-news/watch-rohit-sharma-stops-his-car-in-mumbai-to-wish-fan-on-her-birthday-784?utm_campaign=social_share&utm_medium=social_share",
    "date": "2024-10-09T06:49:10.993Z",
    "type": "news",
    "href": "https://cricketgully.com/cricket-news/watch-rohit-sharma-stops-his-car-in-mumbai-to-wish-fan-on-her-birthday-784",
    "views": "5.6m"
  }
]

const AuthorsInstance = () => {
  const navigate = useNavigate();
  const [displayDataCount, setDisplayDataCount] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const { username } = useParams();
  const reqObjAuthor = usePost();
  const [num, setNum] = useState(0);
  const { data, isLoading, error } = reqObjAuthor;
  const authorData = data?.data;
  const url = authorData ? `/v1/authors/${authorData?.id}/news?page=1&per_page=1000` : null;
  const { data: newsData, isLoading: isNewsLoading, error: newsError } = useAxiosSWR(url);
  const [fakeLoading, setFakeLoading] = useState(false);

  useEffect(() => {
    reqObjAuthor.makeRequest("v1/authors/findByUsername", { username });
  }, [])

  const apiResponseData = newsData?.data;
  useEffect(() => {
    if (Array.isArray(apiResponseData)) {
      handleFilter("all");
    }
  }, [apiResponseData])

  const [filterBtn, setFilterBtn] = useState("All");
  const handleFilter = (type) => {
    setDisplayDataCount(10);
    if (type !== "all") {
      const filter = newsData?.data?.filter(news => news?.type === type);
      setFilteredData(filter);
    } else {
      setFilteredData(newsData?.data);
    }
  }

  if (isLoading) {
    return <div className='container mx-auto px-4 py-8 break-words'><h2>Loading...</h2></div>
  }

  return (
    <div>
      <div className='mx-1 md:mx-[10px] lg:mx-[10px] xl:mx-[140px] md:my-[80px] mt-1 mb-[40px] md:rounded-[20px] md:py-10 py-6 md:px-[30px]'>
        <div className='bg-white border rounded-[20px] md:p-8 p-3 py-6'>
          <div className="text-[#646464] md:text-sm text-[10px] inline-block md:px-10">
            <Link to={"/"} className="text-[#3A32D1]"> Home </Link>
            <span>{">"}</span>
            <Link to={'/authors'}>
              <span className="ml-1 text-[#3A32D1]">Author</span>
            </Link>
            <span>{">"}</span>
            <span className="ml-1 text-[#3A32D1]">{authorData?.name}</span>
          </div>
          <div className='bg-[#EDF4FF] p-3 md:p-6 mt-6 rounded-2xl'>
            <div className='flex items-center'>
              <img src={authorData?.info?.profile_pic} alt="" className='h-[60px] md:h-[122px] w-[60px] md:w-[122px] rounded-xl' />
              <div className='flex items-center justify-between w-full'>
                <div className='md:p-4 p-[11px]'>
                  <div className='font-bold text-[15px] leading-[15px] md:text-[32px] md:leading-[32px]'>{authorData?.name}</div>
                  <div className='flex gap-1 items-center md:leading-[20px] mt-2 md:mt-4 text-[10px] md:text-[14px] text-[#6C6C6C]'>
                    {authorData?.info?.designation} {authorData?.info?.designation && '•'}
                    <span><img src={post} alt="" /></span>
                    {authorData?.stats?.total_articles} posts
                    <span className='md:hidden'>•</span>
                    <span className='md:hidden'><img src={views} alt="" /></span>
                    <span className='md:hidden'> 5m Views</span>
                  </div>
                  <div className='text-[18px] font-semibold leading-[18px] mt-[22px] hidden md:block'>{authorData?.info?.bio}</div>
                </div>
                <div className='hidden md:block pr-4'>
                  <div className='w-max mx-auto'>
                    <img src={view} alt="" />
                  </div>
                  <div className='text-[10px] md:text-[12px] md:font-medium text-[#6C6C6C] text-center'>
                    {authorData?.stats?.total_views}
                  </div>
                  <div className='text-[10px] md:text-[12px] md:font-medium text-[#B2B3B5]'>
                    Views
                  </div>
                </div>
              </div>
            </div>
            <div className='text-[12px] leading-[16px] mt-[20px] md:hidden'>I am an ardent cricket fan and excited to watch any match that is going on around the globe.</div>
          </div>
        </div>
        <div className='bg-white border rounded-[20px] mt-[16px] md:mt-[28px] md:p-[32px] py-[24px] px-[16px]'>
          <div className='flex text-center gap-3'>
            <div className={`md:w-[185px] px-6 md:px-0 border md:rounded-[14px] rounded-[8px] text-[10px] md:text-[16px] leading-4 py-[12px] md:py-[15px] cursor-pointer ${filterBtn === "All" ? "bg-[#3A32D1] text-white border-none" : ""}`} onClick={() => { handleFilter('all'), setFilterBtn('All') }}>All</div>
            <div className={`md:w-[185px] px-6 md:px-0 border md:rounded-[14px] rounded-[8px] text-[10px] md:text-[16px] leading-4 py-[12px] md:py-[15px] cursor-pointer ${filterBtn === "NormalArticles" ? "bg-[#3A32D1] text-white border-none" : ""}`} onClick={() => { handleFilter('news'), setFilterBtn('NormalArticles') }}>Normal Articles</div>
            <div className={`md:w-[185px] px-6 md:px-0 border md:rounded-[14px] rounded-[8px] text-[10px] md:text-[16px] leading-4 py-[12px] md:py-[15px] cursor-pointer ${filterBtn === "FantasyArticles" ? "bg-[#3A32D1] text-white border-none" : ""}`} onClick={() => { handleFilter('fantasy'), setFilterBtn('FantasyArticles') }}>Fantasy Articles</div>
          </div>
          <div className='mt-2'>
            {filteredData?.length === 0 ? <div className='mt-4'>No articles found in this category</div> : null}
            {
              filteredData.slice(0, displayDataCount).map((obj, i) => (
                <Link key={`${obj.id}_${i}`} to={obj?.href} target='_blank'>
                  <div className={`flex py-4 md:py-[30px] ${i === displayDataCount - 1 ? "pb-0" : "border-b"}`}>
                    <div>
                      <img src={obj.image} alt="img" className='md:min-w-[146px] min-w-[71px] max-w-[71px] h-[51px] md:max-w-[146px] md:h-[104px] rounded-[14px]' />
                    </div>
                    <div className='md:pt-[8px] pl-3 md:pl-6'>
                      <div className='font-semibold text-[12px] md:text-[18px] leading-[14px] md:leading-[24px] news-title min-h-[28px] md:min-h-[54px] md:pr-[130px] pr-6'>
                        {obj.title}
                      </div>
                      <div className='text-[#999999] text-[10px] md:text-[16px] font-medium mt-1 md:mt-[14px]'>
                        {formatDate(obj.date, true)}
                      </div>
                    </div>
                    <div className='text-center ml-auto'>
                      <div className='w-max mx-auto'>
                        <img src={view} alt="" />
                      </div>
                      <div className='text-[10px] md:text-[12px] md:font-medium text-[#6C6C6C]'>
                        {obj.views}
                      </div>
                      <div className='text-[10px] md:text-[12px] md:font-medium text-[#B2B3B5]'>
                        {obj?.cnt_views}
                        <span className='block'>Views</span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            }
            {(displayDataCount <= newsData?.total && displayDataCount <= filteredData.length) ?
              <LoadMoreButton fakeLoading={fakeLoading} onClick={() => {
                setFakeLoading(true);
                setTimeout(() => {
                  setDisplayDataCount(prev => prev + 10);
                  setFakeLoading(false);
                }, 2000);
              }} />
              : null
            }
          </div>
        </div>
      </div>
    </div >
  )
}

export default AuthorsInstance

function LoadMoreButton({ fakeLoading, onClick }) {
  return (
    <div className='flex justify-center'>
      <button disabled={fakeLoading} className={`bg-[#3A32D1] text-[#fff] rounded-[50px] px-4 py-2 mt-2 shadow-sm font-medium ${fakeLoading ? ' opacity-75 ' : ''}`} onClick={onClick}>
        {fakeLoading ? 'Loading....' : 'Load More'}
      </button>
    </div>
  )
}